import { useStore } from "@nanostores/react";
import type { CallStatusTypes } from "@root/framework/enums/call-status";
import { incomingCallDataStore } from "@root/framework/stores/calls-store";
import { navigate } from "astro:transitions/client";
import { useEffect, useRef } from "react";

const IncomingCallModal = () => {

    const $incomingCallData = useStore(incomingCallDataStore);
    const audioPlayerRef = useRef<HTMLAudioElement>();

    useEffect(() => {
        switch ($incomingCallData.callStatus as CallStatusTypes) {
            case "INCOMING_CALL":
                audioPlayerRef.current.play();
                break;
            case "LOST_CALL":
                audioPlayerRef.current.pause();
                audioPlayerRef.current.currentTime = 0;
                break;
        }
    }, [$incomingCallData.callStatus]);

    const handleAnswerCall = () => {
        incomingCallDataStore.set({ ...$incomingCallData, callStatus: "ANSWERED_CALL" });
        stopAudio();
        navigate(`/calls/${$incomingCallData.callId}/answer`);
    };

    const handleRejectCall = () => {
        incomingCallDataStore.set({ ...$incomingCallData, callStatus: "CALL_REJECTED" });
        stopAudio();
    };

    const stopAudio = () => {
        audioPlayerRef.current.pause();
        audioPlayerRef.current.currentTime = 0;
    };

    return (
        <>
            <audio src="/assets/ringing.mp3" ref={audioPlayerRef} />
            <div className={`absolute top-0 left-0 w-screen h-screen ${$incomingCallData.callStatus === "INCOMING_CALL" ? "flex" : "hidden"} items-center justify-center bg-black bg-opacity-95 z-50`}>
                <div
                    className="flex items-center justify-center w-[80%] md:w-[70%] lg:w-[50%] xl:w-[35%] h-[80%] bg-white rounded-lg"
                >
                    <div className="w-full h-max flex flex-col items-center gap-12">
                        <div className="bg-[#EA96BF] bg-opacity-30 p-2 w-60 h-60 rounded-full">
                            <div
                                className="bg-[#EA96BF] bg-opacity-30 p-2 w-full h-full rounded-full flex justify-center items-center"
                            >
                                <img
                                    className="w-full h-full rounded-full bg-opacity-30 object-center p-4"
                                    alt="user-picture"
                                    src={$incomingCallData.fromPicture}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2">
                            <p className="uppercase font-bold text-gray-700">{$incomingCallData.fromName}</p>
                            <p className="uppercase font-bold text-gray-400">te esta llamando</p>
                        </div>
                        <div
                            className="flexjustify-center w-screen text-center sm:pb-10 bottom-12 space-x-10"
                        >

                            <button
                                id="reject-call-button"
                                onClick={handleRejectCall}
                                type="button"
                                className="text-white border bg-[#EB5545] hover:text-white focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:hover:text-white"
                            >
                                <svg
                                    className="w-10 h-10"
                                    aria-hidden="true"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="-3 0 34 12"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.96668 10.1333L1.28335 7.50825C1.05002 7.27492 0.93335 7.0027 0.93335 6.69159C0.93335 6.38047 1.05002 6.10825 1.28335 5.87492C2.99446 4.0277 4.96807 2.64228 7.20418 1.71867C9.44029 0.795058 11.7056 0.333252 14 0.333252C16.2945 0.333252 18.5549 0.795058 20.7813 1.71867C23.0077 2.64228 24.9861 4.0277 26.7167 5.87492C26.95 6.10825 27.0667 6.38047 27.0667 6.69159C27.0667 7.0027 26.95 7.27492 26.7167 7.50825L24.0333 10.1333C23.8195 10.3471 23.5715 10.4638 23.2896 10.4833C23.0077 10.5027 22.75 10.4249 22.5167 10.2499L19.1334 7.68325C18.9778 7.56659 18.8611 7.43047 18.7833 7.27492C18.7056 7.11936 18.6667 6.94436 18.6667 6.74992V3.42492C17.9278 3.19159 17.1695 3.00686 16.3917 2.87075C15.6139 2.73464 14.8167 2.66659 14 2.66659C13.1833 2.66659 12.3861 2.73464 11.6083 2.87075C10.8306 3.00686 10.0722 3.19159 9.33335 3.42492V6.74992C9.33335 6.94436 9.29446 7.11936 9.21668 7.27492C9.13891 7.43047 9.02224 7.56659 8.86668 7.68325L5.48335 10.2499C5.25002 10.4249 4.99238 10.5027 4.71043 10.4833C4.42849 10.4638 4.18057 10.3471 3.96668 10.1333ZM7.00002 4.35825C6.43613 4.64992 5.89168 4.98534 5.36668 5.3645C4.84168 5.74367 4.29724 6.16659 3.73335 6.63325L4.90002 7.79992L7.00002 6.16659V4.35825ZM21 4.41659V6.16659L23.1 7.79992L24.2667 6.69159C23.7028 6.18603 23.1583 5.74853 22.6334 5.37909C22.1083 5.00964 21.5639 4.68881 21 4.41659Z"
                                        fill="white"
                                    >
                                    </path>
                                </svg>
                            </button>
                            <button
                                id="answer-call-button"
                                onClick={handleAnswerCall}
                                type="button"
                                className="text-white bg-[#67CE67] hover:text-white focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:hover:text-white"
                            >
                                <svg
                                    className="w-10 h-10"
                                    aria-hidden="true"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="-8 0 42 25"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M24.2625 25.75C24.6875 25.75 25.0417 25.6083 25.325 25.325C25.6083 25.0417 25.75 24.6875 25.75 24.2625V18.525C25.75 18.2181 25.6437 17.9406 25.4313 17.6927C25.2188 17.4448 24.9472 17.2736 24.6167 17.1792L19.7292 16.1875C19.3986 16.1403 19.0622 16.1698 18.7198 16.276C18.3774 16.3823 18.1 16.5417 17.8875 16.7542L14.5583 20.0833C13.6611 19.5639 12.8111 18.9913 12.0083 18.3656C11.2056 17.7399 10.4382 17.0611 9.70625 16.3292C8.92708 15.5736 8.21285 14.7885 7.56354 13.974C6.91424 13.1594 6.35347 12.3153 5.88125 11.4417L9.31667 7.97083C9.50556 7.78194 9.63542 7.55764 9.70625 7.29792C9.77708 7.03819 9.78889 6.71944 9.74167 6.34167L8.82083 1.38333C8.77361 1.07639 8.62014 0.810764 8.36042 0.586458C8.10069 0.362153 7.80556 0.25 7.475 0.25H1.7375C1.3125 0.25 0.958334 0.391667 0.674999 0.675C0.391666 0.958333 0.25 1.3125 0.25 1.7375C0.25 4.68889 0.893402 7.60486 2.18021 10.4854C3.46701 13.366 5.29097 15.9868 7.65208 18.3479C10.0132 20.709 12.634 22.533 15.5146 23.8198C18.3951 25.1066 21.3111 25.75 24.2625 25.75ZM4.57083 8.75C4.16945 7.82917 3.8625 6.89653 3.65 5.95208C3.4375 5.00764 3.27222 4.05139 3.15417 3.08333H6.27083L6.90833 6.4125L4.57083 8.75ZM17.25 21.3583L19.5875 19.0208L22.9167 19.7292V22.8458C21.9486 22.775 20.9924 22.6097 20.0479 22.35C19.1035 22.0903 18.1708 21.7597 17.25 21.3583Z"
                                        fill="white"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default IncomingCallModal